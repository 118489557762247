export default function VouchFaucetButton() {
  return (
    <>
      No frens?{" "}
      <a href="https://creditcub.club/" target="_blank" rel="noreferrer">
        Try CreditCub
      </a>
    </>
  );
}
